export enum Selectors {
  Active = 'is-active',
  Disabled = 'is-disabled',
  Hidden = 'is-hidden',
  Loading = 'is-loading',
  Loaded = 'is-loaded',
  Invalid = 'is-invalid',
  Valid = 'is-valid',
  Accent = 'is-accent',
  AdminPanelVisible = 'admin-panel-visible',
  HasModal = 'has-modal',
  ColorTransparent = 'color-transparent',
  Completed = 'is-completed',
  DropdownOpened = 'dropdownOpened',
}
