/**
 * @description Immutable empty object
 * @returns {Record<string, never>} - An empty object
 *
 * **Important**: This object is frozen to prevent any modifications. Use with caution.
 */
export const EMPTY_OBJECT = Object.freeze({}) as Record<string, never>;

/**
 * @description Immutable empty array
 * @returns {never[]} - An empty array
 *
 * **Important**: This array is frozen to prevent any modifications. Use with caution.
 */
export const EMPTY_ARRAY = Object.freeze([]) as never[];
