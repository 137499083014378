import {
  createRoutes,
} from 'typesafe-routes';
import { renderRoute } from '@/controllers/router/type-safe-routes/renderRoute';
import { replaceRouteParams } from '@/controllers/router/type-safe-routes/replaceRouteParams';
import { study } from '@/controllers/router/type-safe-routes/study.routes';
import { payment } from '@/controllers/router/type-safe-routes/payment.routes';
import { learn } from '@/controllers/router/type-safe-routes/learn.routes';
import { subscriptions } from '@/controllers/router/type-safe-routes/subscriptions.routes';
import { guidedOnboarding } from '@/controllers/router/type-safe-routes/guidedOnboarding.routes';
import { achievements } from '@/controllers/router/type-safe-routes/achievements.routes';
import { subscription } from '@/controllers/router/type-safe-routes/subscription.routes';
import { permissions } from '@/controllers/router/type-safe-routes/permissions.routes';
import { payments } from '@/controllers/router/type-safe-routes/payments.routes';
import { events } from '@/controllers/router/type-safe-routes/events.routes';
import { english } from '@/controllers/router/type-safe-routes/english.routes';
import { employmentGuide } from '@/controllers/router/type-safe-routes/employmentGuide.routes';
import { chats } from '@/controllers/router/type-safe-routes/chats.routes';
import { settings } from '@/controllers/router/type-safe-routes/settings';
import { lmsEditor } from '@/controllers/router/type-safe-routes/lmsEditor';

const routesConfig = {
  study,
  learn,
  home: {
    path: ['home'],
  },
  notFound: {
    path: ['404'],
  },
  forbidden: {
    path: ['403'],
  },
  achievements,
  subscriptions,
  subscription,
  guidedOnboarding,
  payment,
  payments,
  events,
  english,
  employmentGuide,
  chats,
  permissions,
  streaks: {
    path: ['streaks'],
  },
  settings,
  lmsEditor,
  signIn: {
    path: ['/sign-in'],
  },
};

export const allRoutes = createRoutes(routesConfig);

export type AllRoutes = typeof allRoutes;

export {
  renderRoute,
  replaceRouteParams,
};
