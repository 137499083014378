import { isBrowser } from '@/middleware/helpers/isBrowser';
import {
  useChatMessageUpdatedWsSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMessageUpdatedWS.subscription.generated';
import { EMPTY_OBJECT } from '@/constants';

interface Params {
  skip?: boolean;
}

export const useChatMessageUpdatedWS = ({ skip }: Params = EMPTY_OBJECT) => {
  useChatMessageUpdatedWsSubscription({
    skip: !isBrowser || skip,
    onData: ({
      client: { cache },
      data: { data },
    }) => {
      const receivedData = data?.chatMessageUpdatedWS;

      if (!receivedData) {
        return;
      }

      const {
        messageId, messageType, updatedAt, updatedFields,
      } = receivedData;

      cache.modify({
        id: `${messageType}:${messageId}`,
        fields: {
          text: () => updatedFields.text,
          updatedAt: () => updatedAt,
        },
      });
    },
  });
};
