import { UnreadMessagesCacheHelper } from '@/components/platform/Chat/cache.helpers/unreadMessages.helper';
import {
  useUnreadChatMessageDeletedWsSubscription,
} from '@/components/platform/Chat/graphql/generated/unreadChatMessageDeletedWS.subscription.generated';
import { decrementChatFragmentUnreadMessages } from '@/components/platform/Chat/store.helpers';
import { EMPTY_OBJECT } from '@/constants';
import { isBrowser } from '@/middleware/helpers/isBrowser';

interface Params {
  skip?: boolean;
}

export const useUnreadChatMessageDeletedWS = (
  { skip }: Params = EMPTY_OBJECT,
) => {
  useUnreadChatMessageDeletedWsSubscription({
    skip: !isBrowser || skip,
    onData: async ({
      client,
      data: { data },
    }) => {
      const deletedMessage = data?.unreadChatMessageDeletedWS.deletedMessage;

      if (!deletedMessage) {
        return;
      }

      const unreadMessagesCacheHelper = new UnreadMessagesCacheHelper(client);

      const { chatId } = deletedMessage;

      decrementChatFragmentUnreadMessages(client.cache, chatId);

      const hasMoreUnreadMessages = await unreadMessagesCacheHelper
        .hasMoreUnreadMessages();

      unreadMessagesCacheHelper.setHasUnreadMessagesFlag(hasMoreUnreadMessages);
    },
  });
};
