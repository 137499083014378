import { type ComponentType, type ForwardRefExoticComponent } from 'react';
import { useField, type UseFieldConfig } from 'react-final-form';
import { composeValidators } from '@/controllers/forms/forms.helpers';
import { emptyFunction } from '@/lib/helpers/functional';
import { EMPTY_OBJECT } from '@/constants';

const defaultValidator = emptyFunction;
const defaultConfig = EMPTY_OBJECT;

export const withFinalFormController = <FieldValue, >(
  baseConfig: UseFieldConfig<FieldValue> = defaultConfig,
) => <P, >(InputUI: ComponentType<P> | ForwardRefExoticComponent<P>) => {
  interface Props {
    name: string;
    config?: UseFieldConfig<FieldValue>;
    id?: string;
    positive?: boolean;
  }

  return (props: P & Props) => {
    const { config: propsConfig = EMPTY_OBJECT, ...rest } = props;

    const data = useField(props.name, {
      ...baseConfig,
      ...propsConfig,
      validate: composeValidators<FieldValue>(
        propsConfig.validate || defaultValidator,
        baseConfig.validate || defaultValidator,
      ),
    });

    return (
      <InputUI
        {...rest as P}
        {...data.input}
      />
    );
  };
};
