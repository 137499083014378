import { isBrowser } from '@/middleware/helpers/isBrowser';
import { MentionCacheHelper } from '@/components/platform/Chat/cache.helpers/mention.helper';
import {
  useChatMentionDeletedWsSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMentionDeletedWS.subscription.generated';
import { EMPTY_OBJECT } from '@/constants';

interface Params {
  skip?: boolean;
}

export const useChatMentionDeletedWS = ({ skip }: Params = EMPTY_OBJECT) => {
  useChatMentionDeletedWsSubscription({
    skip: !isBrowser || skip,
    onData: ({ client, data: { data } }) => {
      if (!data) {
        return;
      }

      const { chatId } = data.chatMentionDeletedWS;

      const cacheHelper = new MentionCacheHelper(client.cache);

      cacheHelper.decrementGlobalChatMentionsCount();

      cacheHelper.decrementChatUnreadMentionsCount(chatId);

      cacheHelper.decrementChatMentionsCounter(chatId);
    },
  });
};
