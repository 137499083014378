import { isBrowser } from '@/middleware/helpers/isBrowser';
import { removeFromPinnedMessagesList } from '@/components/platform/Chat/store.helpers';
import {
  useChatMessageUnpinnedWsSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMessageUnpinnedWS.subscription.generated';
import { EMPTY_OBJECT } from '@/constants';

interface Params {
  skip?: boolean;
}

export const useChatMessageUnpinnedWS = ({ skip }: Params = EMPTY_OBJECT) => {
  useChatMessageUnpinnedWsSubscription({
    skip: !isBrowser || skip,
    onData: ({
      client: { cache },
      data: { data },
    }) => {
      const receivedPayload = data?.chatMessageUnpinnedWS;

      if (!receivedPayload) {
        return;
      }

      const { messageId, messageType, chatId } = receivedPayload;

      cache.modify({
        id: `${messageType}:${messageId}`,
        fields: {
          pinnedBy: () => null,
        },
      });

      removeFromPinnedMessagesList(
        cache,
        {
          id: messageId,
          chatId,
        },
      );
    },
  });
};
