import { Router } from '@/middleware/i18n';
import { type PageContext } from '@/controllers/page/page.typedefs';
import { DEFAULT_LOCALE } from '@/middleware/i18n/i18n.config';
import { i18nReplaceLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { sanitizeRedirectUrl } from '@/lib/sanitizeRedirectUrl';
import { EMPTY_ARRAY } from '@/constants';

export enum RedirectAction {
  Push = 'push',
  Replace = 'replace',
}

export const redirect = (
  context: PageContext | Record<string, never>,
  target: string,
  options: {
    action: RedirectAction;
  } = {
    action: RedirectAction.Push,
  },
) => {
  const sanitizedUrl = sanitizeRedirectUrl(target);

  if (context.res) {
    // server
    // 303: "See other"
    const locale = context.locale === DEFAULT_LOCALE
      ? ''
      : context.locale;

    const location = i18nReplaceLanguageSubpath({
      url: sanitizedUrl,
      language: locale,
      supportedLanguages: context.locales ?? EMPTY_ARRAY,
    });

    context.res.writeHead(303, { Location: location });
    context.res.end();
  } else {
    // browser
    Router[options.action](sanitizedUrl);
  }
};

redirect.action = RedirectAction;
